/* eslint-disable indent */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, TextareaAutosize } from '@mui/material';

import { useGetLocalApiQuery, useUpdateLocalApiMutation, useDeleteApiKeyMutation } from '../../../services';

import { Switcher } from '../../../../../components/ReduxFormFields';
import { Tooltip, Loader } from '../../../../../components';
import i18n from '../../../../../i18n';
import { APP_ID, SOLAR_MANAGER_APP_ID } from '../../../../../config';

const ZOHO_LINK_LOCAL_API = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/solar-manage';

const LocalApi = ({ user, isReadOnlyPermission = false }) => {
  const apiKeysContainer = useRef(null);
  const localValues = useRef({});
  const [localKeys, setLocalKeys] = useState([]);

  const { data = {}, isLoading } = useGetLocalApiQuery(
    user.gateway?._id,
    { skip: !user.gateway?._id }
  );

  const [deleteApiKey] = useDeleteApiKeyMutation();

  const [updateLocalApi] = useUpdateLocalApiMutation();

  const onChange = (field) => updateLocalApi({ data: field, gatewayId: user.gateway?._id });

  const onSaveLocalKey = (_id) => {
    const apiKey = localValues.current[_id];

    if (apiKey) {
      updateLocalApi({
        data: { apiKey },
        gatewayId: user.gateway?._id,
        cb: () => {
          const newLocalKeys = localKeys.filter((key) => key._id !== _id);
          setLocalKeys(newLocalKeys);
        }
      });
    }
  };

  const onRemoveApiKey = (apiKeyId) => deleteApiKey({ apiKeyId, gatewayId: user.gateway?._id });

  const onRemoveLocalKey = (_id) => {
    const newLocalKeys = localKeys.filter((key) => key._id !== _id);
    setLocalKeys(newLocalKeys);
  };

  const addLocalKey = () => {
    setLocalKeys([...localKeys, { _id: crypto.getRandomValues(new Uint32Array(1))[0], key: '' }]);
  };

  const handleOnInput = (e, _id) => {
    const prevData = { ...localValues?.current };
    localValues.current = { ...prevData, [_id]: e.nativeEvent.target.value };
  };

  return (
    <Box
      className="main-info"
      sx={{
        px: 2,
        pt: 1,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
        position: 'relative',
        pointerEvents: isReadOnlyPermission ? 'none' : 'auto',
        opacity: isReadOnlyPermission ? 0.7 : 1
      }}
    >
      {isLoading && <Loader />}
      <Typography className="list-item-title">
        {i18n.t('activatedProtocols')}
        <Tooltip title={i18n.t('activatedProtocolsTooltip')} />
      </Typography>
      <Typography
        component="div"
        className="list-item-title general-tab-item-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '16px'
        }}
      >
        <Switcher
          input={{
            value: data?.httpEnabled,
            onChange: () => onChange({ httpEnabled: !data?.httpEnabled })
          }}
        />
        HTTP
      </Typography>
      <Typography
        component="div"
        className="list-item-title general-tab-item-title"
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 0,
          gap: 1,
          fontSize: '16px'
        }}
      >
        <Switcher input={{ value: data?.httpsEnabled, onChange: () => onChange({ httpsEnabled: !data?.httpsEnabled }) }} />
        HTTPS
      </Typography>
      <Typography className="list-item-title">
        {i18n.t('apiKeys')}
        <Tooltip title={i18n.t('apiKeysTooltip')} />
      </Typography>

      {!data?.apiKeys?.length && (
        <Typography className="list-item-title">
          {i18n.t('apiKeysDescription')}
        </Typography>
      )}

      {Boolean(data?.apiKeys?.length || localKeys.length) && (
        <Box
          ref={apiKeysContainer}
          sx={{
            width: '100%',
            maxWidth: '700px',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflowY: 'auto',
            maxHeight: '200px',
            scrollbarWidth: 'thin'
          }}
        >
          {data.apiKeys?.map((key) => (
            <Box
              className="list-item-title general-tab-item-title"
              key={key._id}
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%'
              }}
            >
              <Typography
                sx={{ maxWidth: '600px', width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                className="list-item-title api-key-item"
              >
                {key.key}
              </Typography>
              <button
                onClick={() => onRemoveApiKey(key._id)}
                type="button"
                className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
              >
                <i className="la la-trash" />
              </button>
            </Box>
          ))}

          {localKeys.map((key) => (
            <Box
              className="list-item-title general-tab-item-title"
              key={key._id}
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%'
              }}
            >
              <Typography sx={{ maxWidth: '600px', width: '100%' }} className="list-item-title api-key-item">
                <TextareaAutosize
                  autoFocus
                  style={{ maxWidth: '600px', width: '100%' }}
                  onChange={(e) => handleOnInput(e, key._id)}
                  className="list-item-title"
                />
              </Typography>
              <button
                type="button"
                className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
                onClick={() => onSaveLocalKey(key._id)}
              >
                <i className="la la-check" />
              </button>
              <button
                type="button"
                className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
                onClick={() => onRemoveLocalKey(key._id)}
              >
                <i className="la la-trash" />
              </button>
            </Box>
          ))}

        </Box>
      )}
      <button
        type="button"
        className="button button-fourth button-small"
        onClick={addLocalKey}
      >
        {i18n.t('addPApiKey')}
      </button>

      {APP_ID === SOLAR_MANAGER_APP_ID && (
        <a
          className="zev-zoho-link"
          href={ZOHO_LINK_LOCAL_API}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i18n.t('localApiMoreDetails')}
        </a>
      )}

      <Box className="software-container" />
    </Box>
  );
};

LocalApi.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  isReadOnlyPermission: PropTypes.bool
};

export default LocalApi;
