import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import columns from '../../columns';
import { DataListHocForDevicesTable } from '../../../DataList';
import { SENSORS_LIST_ID } from '../../../UserDetails/constants';
import { usersSensorsRequest } from '../../slice';
import { ADD_DEVICE_MODAL_ID, openModalWindow, UPGRADE_TO_UNLOCK_POPUP } from '../../../ModalWindow/slice';

import i18n from '../../../../i18n';
import { addNotification } from '../../../NotificationGenerator/slice';

/**
 * @module DevicesTable
 */
/**
 * Load Guard tab from DevicesTable
 * @memberof module:DevicesTable
 */
const LoadGuardTab = (props) => {
  const {
    transformResponse,
    defaultParams,
    loadGuardUrl,
    widthSetting,
    myRoleType,
    getNewTags,
    userId,
    total,
    isReadOnlyPermission,
    license
  } = props;
  const dispatch = useDispatch();

  const sensors = useSelector(({ dataList }) => dataList?.sensors?.data || []);
  const isIncludeFeedIn = sensors.some((sensor) => sensor.type === 'feed_in');

  const addLoadGuardHandler = () => {
    if (license?.restrictions?.allowedDeviceTypes?.length && !license.restrictions.allowedDeviceTypes.includes('loadguards')) {
      dispatch(license.type === 'initial' ? addNotification({ type: 'error', text: i18n.t('forbidden') }) : openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
    } else {
      getNewTags();
      dispatch(usersSensorsRequest({ userId }));
      dispatch(openModalWindow({ modalID: ADD_DEVICE_MODAL_ID, data: null }));
    }
  };

  const addFeedInHandler = (e) => {
    if (license?.restrictions?.allowedDeviceTypes?.length && !license.restrictions.allowedDeviceTypes.includes('loadguards')) {
      e.preventDefault();
      dispatch(license.type === 'initial' ? addNotification({ type: 'error', text: i18n.t('forbidden') }) : openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
    }
  };

  const feedInUrl = `/feed-in-management/create/${userId}`;

  return (
    <>
      {!(['viewer', 'pv_installer_employee_read_only', 'end_user', 'oem_employee_read_only'].includes(myRoleType) || isReadOnlyPermission) ? (
        <div className="devices-tab-panel col-auto d-flex">
          <Link
            to={feedInUrl}
            className={`btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right mr-3 ${isIncludeFeedIn ? 'disabled' : ''}`}
            onClick={addFeedInHandler}
          >
            <Tooltip
              title={<div className="support-btn-container">{i18n.t('addFeedInManagementTooltip')}</div>}
              classes={{ tooltip: 'support-btn-tooltip', popper: 'support-btn-popper' }}
              enterTouchDelay={0}
              leaveTouchDelay={10000}
              placement="bottom"
            >
              <span>{i18n.t('addFeedInManagement')}</span>
            </Tooltip>
          </Link>
          <a
            onClick={addLoadGuardHandler}
            className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-info-user right"
          >
            <span>{i18n.t('addLoadGuard')}</span>
          </a>
        </div>
      ) : null}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        <DataListHocForDevicesTable
          tab="loadguards"
          listID={SENSORS_LIST_ID}
          listURL={loadGuardUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          columns={columns(myRoleType, userId, 'loadguards', total, isReadOnlyPermission, widthSetting)}
        />
      </div>
    </>
  );
};

LoadGuardTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  loadGuardUrl: PropTypes.string.isRequired,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  getNewTags: PropTypes.func.isRequired,
  transformResponse: PropTypes.func.isRequired,
  widthSetting: PropTypes.instanceOf(Object),
  license: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default LoadGuardTab;
