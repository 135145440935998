export const allowedInputDevices = ['GUDE 2302', 'Shelly Pro 4PM Input', 'Shelly Pro 2PM Input', 'Shelly Pro 1PM Input', 'SmartMeter Input'];

export const allowedInverters = [
  'Kostal PLENTICORE plus',
  'Huawei SUN2000',
  'SolarEdge SE',
  'Fronius TCP Inv',
  'ABB B-Series Inv',
  'ZZZ Inv'
];

export const relaisNameByDeviceGroup = {
  'GUDE 2302': 'relaisNumber8',
  'Shelly Pro 4PM Input': 'relaisNumber4Input',
  'Shelly Pro 2PM Input': 'relaisNumber2Input',
  'Shelly Pro 1PM Input': 'relaisNumber2Input'
};

export const expectedLengthByDeviceGroup = {
  'GUDE 2302': 8,
  'Shelly Pro 4PM Input': 4,
  'Shelly Pro 2PM Input': 2,
  'Shelly Pro 1PM Input': 2,
  'SmartMeter Input': 0
};

export const defaultRegulations = [
  { digital_input: [0, 0, 0, 0], performance: 0 },
  { digital_input: [0, 0, 0, 0], performance: 100 }
];

export const FEED_IN_FORM_ID = 'feedInForm';

export const STATIC_COLUMNS_COUNT = 3;

export const CONFIG_FEED_IN_MODES = [
  { name: 'limitedFeedInPower', allowedFor: [1] },
  { name: 'performance', allowedFor: [1] },
  { name: 'absolute_power', allowedFor: [2] },
  { name: 'controlled_devices', allowedFor: [1, 2] }
];

export const CONFIG_RIPPLE_CONTROL_RECEIVER_MODES = [
  { name: 'fallback', allowedFor: [1, 2] },
  { name: 'input_device', allowedFor: [1, 2] },
  { name: 'controlled_devices', allowedFor: [1, 2] },
  { name: 'regulations', allowedFor: [1, 2] }
];
