import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';
import { PROVIDER_OAUTH_URL } from '../../../api/apiUrls';
import { addNotification } from '../../NotificationGenerator/slice';
import i18n from '../../../i18n';

export const userDetailsApi = createApi({
  reducerPath: 'userDetailsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['UserDetails', 'LocalApi'],
  endpoints: (builder) => ({
    getFirmwareVersion: builder.query({
      query: ({ gateway }) => ({ url: `/firmware/current/${gateway}`, method: 'get' })
    }),
    startSoftwareUpdate: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ data }) => ({ url: '/firmware/latest', method: 'put', data })
    }),
    getShellhubData: builder.query({
      query: ({ supportId }) => ({ url: `/gateway/info?supportId=${supportId}`, method: 'get' })
    }),
    startOauth2ForProviders: builder.query({
      refetchOnMountOrArgChange: true,
      query: (data) => {
        const query = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&');
        return { url: `${PROVIDER_OAUTH_URL}?${query}`, method: 'get' };
      },

      async onQueryStarted(_args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          window.location.href = data.authUrl;
        } catch (error) {
          console.log(error, 'error');
        }
      }
    }),
    sendIOTCommand: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ data }) => ({ url: '/gateway/command', method: 'post', data })
    }),

    getLocalApi: builder.query({
      providesTags: ['LocalApi'],
      query: (gatewayId) => ({ url: `/gateways/${gatewayId}/local-server`, method: 'get' }),

      async onQueryStarted(_args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error?.message || i18n.t('error') }));
        }
      }
    }),

    updateLocalApi: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ data, gatewayId }) => ({ url: `/gateways/${gatewayId}/local-server`, method: 'patch', data }),

      // after reSPONSEe, update the local api
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(userDetailsApi.util.updateQueryData('getLocalApi', args.gatewayId, (draft) => {
            Object.assign(draft, data);
          }));

          dispatch(addNotification({ type: 'success', text: i18n.t('success') }));

          if (args.cb) {
            args.cb();
          }
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error?.message || i18n.t('error') }));
          console.log(error, 'error');
        }
      }
    }),

    deleteApiKey: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ gatewayId, apiKeyId }) => ({ url: `/gateways/${gatewayId}/local-server/api-keys/${apiKeyId}`, method: 'delete' }),

      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(userDetailsApi.util.updateQueryData('getLocalApi', args.gatewayId, (draft) => {
            Object.assign(draft, data);
          }));

          dispatch(addNotification({ type: 'success', text: i18n.t('success') }));

          if (args.cb) {
            args.cb();
          }
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error?.message || i18n.t('error') }));
        }
      }
    })

  })
});

export const {
  useStartSoftwareUpdateMutation,
  useGetShellhubDataQuery,

  useLazyGetFirmwareVersionQuery,
  useLazyStartOauth2ForProvidersQuery,

  useSendIOTCommandMutation,

  useGetLocalApiQuery,
  useUpdateLocalApiMutation,
  useDeleteApiKeyMutation
} = userDetailsApi;
