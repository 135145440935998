import { uniqBy } from 'lodash';
import i18n from '../../../i18n';

/**
 * Gets devices names by some type
 * @function getNamesByType
 * @memberof module:UserDevices
 * @param {Object[]} devicesList Array of devices
 * @param {string} selectedType Type of devices to get.
 * @param {Object} deviceGroupRestriction Object with restrictions for device group.
 * @returns {Object[]} Array of unique names of devices. Type is an Object: { value, label }.
 */
const getNamesByType = (devicesList = [], selectedType = '', allowedDeviceGroupsByType = {}, licenseType = '') => uniqBy(devicesList
  .filter(({ type }) => type === selectedType)
  .map(({ name, hardware: deviceHardware }) => ({
    value: name,
    label: i18n.exists(name) ? i18n.t(name) : name,
    deviceHardware,
    disabled: allowedDeviceGroupsByType[selectedType] && !allowedDeviceGroupsByType[selectedType]?.includes(name),
    isOpenUpgradePopup: allowedDeviceGroupsByType[selectedType] && !allowedDeviceGroupsByType[selectedType]?.includes(name),
    licenseType
  }))
  .sort(({ label: firstLabel }, { label: secondLabel }) => firstLabel.toLowerCase().localeCompare(secondLabel.toLowerCase())), 'value');

export default getNamesByType;
