import React from 'react';
import { useDispatch } from 'react-redux';
import ModalWindow from '../../containers/ModalWindow';
import { closeModalWindow, FEATURE_NOT_AVAILABLE_POPUP } from '../../containers/ModalWindow/slice';
import { PrimaryButton } from '../../components/UIKit';

import i18n from '../../i18n';
import './index.scss';

const modalID = FEATURE_NOT_AVAILABLE_POPUP;

const FeatureNotAvailable = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModalWindow({ modalID }));
  };

  return (
    <ModalWindow isModalOver customOnClose={handleClose} modalID={modalID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('featureNotAvailable')}</h5>
      </div>

      <div>
        <div className="modal-body">
          {i18n.t('featureNotAvailableDesc')}
        </div>
        <div className="modal-footer">
          <PrimaryButton onClickHandler={handleClose} i18nLabel="ok" />
        </div>
      </div>
    </ModalWindow>
  );
};

export default FeatureNotAvailable;
