export const SENSORS_LIST_ID = 'sensors';

/**
 * Roles for visible web
 */
export const rolesWithCustomLogo = ['oem', 'pv_installer', 'oem_employee', 'oem_employee_read_only'];
export const EMPLOYEE_ROLE_TYPES = ['pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install'];

/**
 * @name houseFuseDefaultValue
 * @constant {Number}
 * @description Return default value If houseFuse equal undefined
 */
export const houseFuseDefaultValue = 0;

/**
 * @name loadManagementValue
 * @constant {Boolean}
 * @description Return default value If loadManagement equal undefined
 */
export const loadManagementValue = false;

export const editTariffId = 'editTariff';

export const tariffTypes = {
  single: 'single',
  double: 'double',
  dynamic: 'dynamic',

  // Helion tariff types
  standard: 'standard',
  fix5: 'fixed5Years',
  fix10: 'fixed10Years'
};

const defaultStepsForDays = {
  mondayFriday: [
    { tariffOption: 'low', fromTime: '00:00' },
    { tariffOption: 'high', fromTime: '07:00' },
    { tariffOption: 'low', fromTime: '20:00' }
  ],
  saturday: [
    { tariffOption: 'low', fromTime: '00:00' },
    { tariffOption: 'high', fromTime: '07:00' },
    { tariffOption: 'low', fromTime: '13:00' }
  ],
  sunday: [{ tariffOption: 'low', fromTime: '00:00' }]
};

export const directMarketing = [
  { label: 'noSolarTariff', value: 'standard' },
  { label: 'stockExchange', value: 'dynamic' },
  { label: 'fix5', value: 'fixed5Years' },
  { label: 'fix10', value: 'fixed10Years' }
];

export const defaultStepsForSeasons = {
  commonSeason: defaultStepsForDays,
  winterSeason: defaultStepsForDays,
  isWinterTimeEnabled: false
  // directMarketing: directMarketing[0]
};

export const unitsTypesByCountry = {
  switzerland: 'CHF/kWh',
  liechtenstein: 'CHF/kWh',
  denmark: 'dkr/kWh',
  default: 'EUR/kWh'
};

export const PROVIDERS_LIST_ID = 'providers';
export const TARIFFS_LIST_ID = 'tariffs';
export const HiSTORICAL_TARIFF_LIST_ID = 'historicalTariff';

export const dataExportZohoLink = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/wie-exportiere-ich-meine-daten-als-csv';
export const dataImportZohoLink = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/wie-lade-ich-historische-daten-von-einem-fremdsystem-in-den-solar-manager';
