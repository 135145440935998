import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Slider } from '@mui/material';
import { greaterThan, lessThan } from '../../../../validation';
import additionalFields from '../../../utils/additionalFields';
import TextField from './TextField';

const EcoMode = (props) => {
  const { formData, change } = props;
  const [sliderValue, setSliderValue] = useState([
    formData?.dischargeSocLimit,
    formData?.morningSocLimit,
    formData?.chargingSocLimit
  ]);

  useEffect(() => {
    if (sliderValue[0] !== formData?.dischargeSocLimit) {
      change('dischargeSocLimit', sliderValue[0]);
    }
    if (sliderValue[1] !== formData?.morningSocLimit) {
      change('morningSocLimit', sliderValue[1]);
    }
    if (sliderValue[2] !== formData?.chargingSocLimit) {
      change('chargingSocLimit', sliderValue[2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue]);

  useEffect(() => {
    if (!formData) return;
    const { dischargeSocLimit, morningSocLimit, chargingSocLimit } = formData;

    const isFieldChanged = dischargeSocLimit !== sliderValue[0]
      || morningSocLimit !== sliderValue[1]
      || chargingSocLimit !== sliderValue[2];
    const isValid = dischargeSocLimit <= morningSocLimit && morningSocLimit <= chargingSocLimit;

    if (isFieldChanged && isValid) {
      setSliderValue([dischargeSocLimit, morningSocLimit, chargingSocLimit]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const lessThanMorningSoc = useMemo(
    () => lessThan(sliderValue[1], 'morningSocLimit.label'),
    [sliderValue]
  );
  const lessThanChargingSoc = useMemo(
    () => lessThan(sliderValue[2], 'chargingSocLimit.label'),
    [sliderValue]
  );
  const greaterThanDischargeSoc = useMemo(
    () => greaterThan(sliderValue[0], 'dischargeSocLimit.label'),
    [sliderValue]
  );
  const greaterThanMorningSoc = useMemo(
    () => greaterThan(sliderValue[1], 'morningSocLimit.label'),
    [sliderValue]
  );

  return (
    <>
      <Slider
        sx={{
          marginBottom: '36px',
          '& .MuiSlider-thumb': {
            color: '#fff'
          },
          '& .MuiSlider-rail': {
            background: `linear-gradient(to right, #F2D649, #F2D649 ${sliderValue[0]}%, #C14F1A ${sliderValue[0]}%, #C14F1A ${sliderValue[1]}%, #1A60C1 ${sliderValue[1]}%, #1A60C1 ${sliderValue[2]}%, #FFFFFF1A ${sliderValue[2]}%, #FFFFFF1A 100%)`,
            opacity: 1
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'unset',
            top: 0,
            color: 'text.primary',
            opacity: 0.5
          }
        }}
        track={false}
        aria-labelledby="track-false-range-slider"
        valueLabelDisplay="on"
        valueLabelFormat={(value) => `${value}%`}
        defaultValue={sliderValue}
        value={sliderValue}
        min={0}
        max={100}
        disableSwap
        onChange={(event, value) => setSliderValue(value)}
      />
      <Box className="field-wrapper">
        <TextField
          {...additionalFields.dischargeSocLimit}
          name="dischargeSocLimit"
          validate={[...additionalFields.dischargeSocLimit.validate, lessThanMorningSoc]}
        />
        <TextField
          {...additionalFields.morningSocLimit}
          name="morningSocLimit"
          validate={[
            ...additionalFields.morningSocLimit.validate,
            lessThanChargingSoc,
            greaterThanDischargeSoc
          ]}
        />
        <TextField
          {...additionalFields.chargingSocLimit}
          name="chargingSocLimit"
          validate={[...additionalFields.chargingSocLimit.validate, greaterThanMorningSoc]}
        />
      </Box>
    </>
  );
};

EcoMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default EcoMode;
