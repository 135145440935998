import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import ModalWindow from '../../../../ModalWindow';
import { ADD_CAR_MODAL, EDIT_CAR_MODAL, TESLA_URL_FORM_ID } from '../constants';
import i18n from '../../../../../i18n';
import { CancelButton, PrimaryButton } from '../../../../../components/UIKit';
import { closeModalWindow, modalWindowSend, openModalWindow } from '../../../../ModalWindow/slice';
import SupportedBrands from './components/SupportedBrands';

import './index.scss';
import LoginIntoAccount from './components/LoginIntoAccount';
import Url from './components/Url';
import SelectCar from './components/SelectCar';
import { POST } from './utils';
import {
  getSignInUserRoleTypeSelector,
  getSignInUser_IdSelector
} from '../../../../../redux-store/selectors/signIn';
import { getUserDevicesUser_IdSelector } from '../../../../../redux-store/selectors/userDevices';
import { dataListReloadData } from '../../../../DataList/slice';
import { getModalAddCar } from '../../../../../redux-store/selectors/modals';
import { companyDataListCarsSelector } from '../../../../../redux-store/selectors/dataList';
import { Preloader } from '../../../../../components';
import { addNotification } from '../../../../NotificationGenerator/slice';
import { useGetCarBrandsListQuery } from '../../../services';
import formatFloatSeparator from '../../../../../utils/formatFloatSeparator';

const AddCar = () => {
  const dispatch = useDispatch();

  const modal = useSelector(getModalAddCar);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState({
    authType: '',
    label: '',
    value: '',
    icon: ''
  });
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [listOfTranslationKeysForTabs, setListOfTranslationKeysForTabs] = useState([
    'supportedBrands',
    'loginIntoAccount',
    'selectCar'
  ]);
  const [vehicles, setVehicles] = useState([]);
  const [teslaRedirectUrl, setTeslaRedirectUrl] = useState('');
  const [linkId, setLinkId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowCarLoginError, setIsShowCarLoginError] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const signInUserId = useSelector(getSignInUser_IdSelector);
  const userDeviceId = useSelector(getUserDevicesUser_IdSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const carsDataList = useSelector(companyDataListCarsSelector);
  const myCarsVin = carsDataList?.data?.map((car) => car.vin);
  const loginIntoAccountForm = useSelector(getFormValues('loginIntoAccount'));
  const teslaUrlForm = useSelector(getFormValues(TESLA_URL_FORM_ID));
  const userId = myRoleType === 'end_user' ? signInUserId : userDeviceId;

  const { data: carBrandsList } = useGetCarBrandsListQuery();

  useEffect(() => {
    setListOfTranslationKeysForTabs(
      selectedBrand?.authType === 'webviewExpert'
        ? ['supportedBrands', 'loginIntoAccount', 'url', 'selectCar']
        : ['supportedBrands', 'loginIntoAccount', 'selectCar']
    );
  }, [selectedBrand?.authType]);

  useEffect(() => {
    if (modal && !modal.opened) {
      setActiveTab(0);
      setSelectedBrand({ authType: '', label: '', value: '', icon: '' });
      setListOfTranslationKeysForTabs(['supportedBrands', 'loginIntoAccount', 'selectCar']);
      setVehicles([]);
      setSelectedVehicle({});
      setIsShowCarLoginError(false);
      setTeslaRedirectUrl('');
    }
    if (modal?.data?.selectedBrand) {
      setSelectedBrand(modal.data.selectedBrand);
    }
    if (modal?.data?.vehicles) {
      setVehicles(modal.data.vehicles);
    }
    if (modal?.data?.linkId) {
      setLinkId(modal.data.linkId);
    }
    if (modal?.data?.activeTab) {
      setActiveTab(modal.data.activeTab);
    }
  }, [modal]);

  const nextTab = () => {
    setActiveTab((prev) => prev + 1);
  };
  const prevTab = () => {
    setIsShowCarLoginError(false);
    setActiveTab((prev) => prev - 1);
  };
  const onClose = () => {
    dispatch(closeModalWindow({ modalID: ADD_CAR_MODAL }));
  };

  const carRelinkHandler = (id) => {
    onClose();
    dispatch(
      openModalWindow({
        modalID: EDIT_CAR_MODAL,
        data: {
          linkId: id,
          vin: modal?.data?.vin,
          name: modal?.data?.name,
          deviceActivity: modal?.data?.deviceActivity,
          data: { batteryCapacity: modal?.data?.data?.batteryCapacity },
          notes: modal?.data?.notes,
          _id: modal?.data?.sensorId
        }
      })
    );

    const url = myRoleType === 'end_user'
      ? `sensors/car-relink/my/${modal?.data?.sensorId}`
      : `/sensors/car-relink/${userId}/${modal?.data?.sensorId}`;

    POST(url, {
      linkId: id,
      vin: modal?.data?.vin,
      name: modal?.data?.name,
      deviceActivity: modal?.data?.deviceActivity
    }).then(() => {
      dispatch(addNotification({ type: 'success', text: i18n.t('success') }));
    });
  };

  const loginIntoAccountHandler = () => {
    setIsLoading(true);

    const authCredentials = {};

    if (selectedBrand?.authType === 'loginForm') {
      authCredentials.username = loginIntoAccountForm?.username || loginIntoAccountForm?.email || '';
      authCredentials.password = loginIntoAccountForm?.password || '';
    }

    POST('v2/sensors/car/login', {
      authCredentials: selectedBrand?.authType === 'loginForm' ? authCredentials : undefined,
      brand: selectedBrand?.value,
      userId,
      authType: selectedBrand.authType,
      redirectUrl: selectedBrand?.authType === 'webview' ? window.location.href : undefined
    })
      .then((response) => {
        if (selectedBrand?.authType === 'webview') {
          localStorage.setItem(
            'car',
            JSON.stringify({
              redirectUrl: response.redirectUrl,
              linkId: response.linkId,
              selectedBrand
            })
          );
          window.location.replace(response.url);
          return;
        }
        setLinkId(response.linkId);
        setTeslaRedirectUrl(response.url);
        const availableCars = response?.vehicles?.filter(
          (vehicle) => !myCarsVin.includes(vehicle.vin)
        );
        setVehicles(availableCars);
        setIsLoading(false);
        setIsShowCarLoginError(false);
        if (modal?.data?.activeTab && selectedBrand?.authType !== 'webviewExpert') {
          carRelinkHandler(response.linkId);
        } else {
          nextTab();
        }
      })
      .catch(() => {
        if (
          (loginIntoAccountForm?.username && loginIntoAccountForm?.password)
          || loginIntoAccountForm?.email
        ) {
          setIsShowCarLoginError(true);
        }
        setIsLoading(false);
      });
  };

  const loginIntoTeslaAccountHandler = () => {
    setIsLoading(true);

    POST('/sensors/car/webviewCode', {
      linkId,
      url: teslaUrlForm.url,
      brand: selectedBrand.value,
      userId
    })
      .then((resp) => {
        if (resp?.vehicles) {
          const availableCars = resp.vehicles?.filter(
            (vehicle) => !myCarsVin.includes(vehicle.vin)
          );
          setVehicles(availableCars);
          setIsLoading(false);
          setIsShowCarLoginError(false);
          if (modal?.data?.activeTab && selectedBrand?.authType === 'webviewExpert') {
            carRelinkHandler(linkId);
          } else {
            nextTab();
          }
        }
      })
      .catch(() => {
        if (teslaUrlForm?.url) {
          setIsShowCarLoginError(true);
        }
        setIsLoading(false);
      });
  };

  const selectCarHandler = () => {
    if (!selectedVehicle.batteryCapacity) {
      setSubmitError(true);
      return;
    }

    dispatch(
      modalWindowSend({
        modalID: ADD_CAR_MODAL,
        requestConfig: {
          method: 'post',
          url: myRoleType === 'end_user' ? '/sensors/car/my' : `/sensors/car/${userId}`,
          data: {
            linkId,
            vins: [selectedVehicle.vin],
            names: [selectedVehicle.title],
            data: formatFloatSeparator({ batteryCapacity: selectedVehicle.batteryCapacity }),
            device_type: 'car',
            type: 'Car',
            brand: selectedBrand?.value,
            device_group: 'Device Link Car',
            notes: ''
          }
        },
        cb: () => dataListReloadData({ listID: 'cars' })
      })
    );

    onClose();
  };

  return (
    <ModalWindow
      modalID={ADD_CAR_MODAL}
      customClass={`car-modal ${selectedBrand?.authType === 'webviewExpert' ? 'tab-4' : 'tab-3'}`}
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {modal?.data?.activeTab ? i18n.t('relinkCarLabel') : i18n.t('addCar')}
        </h5>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-center tabs-row">
          <Tabs value={activeTab}>
            {listOfTranslationKeysForTabs.map((key, idx) => (
              <Tab key={key} value={idx} disableRipple label={`${idx + 1}. ${i18n.t(key)}`} />
            ))}
          </Tabs>
        </div>
        {isLoading && (
          <div className="admin-preloader-container">
            <Preloader />
          </div>
        )}
        {activeTab === 0 && (
          <SupportedBrands setSelectedBrand={setSelectedBrand} carBrandsList={carBrandsList} />
        )}
        {activeTab === 1 && (
          <LoginIntoAccount
            isLoading={isLoading}
            isError={isShowCarLoginError}
            selectedBrand={selectedBrand}
            carBrandsList={carBrandsList}
          />
        )}
        {selectedBrand?.authType === 'webviewExpert' ? (
          <>
            {activeTab === 2 && (
              <Url
                isLoading={isLoading}
                isError={isShowCarLoginError}
                redirectUrl={teslaRedirectUrl}
                selectedBrand={selectedBrand}
              />
            )}
            {activeTab === 3 && (
              <SelectCar
                selectedVehicle={selectedVehicle}
                setSelectedVehicle={setSelectedVehicle}
                vehicles={vehicles}
                submitError={submitError}
              />
            )}
          </>
        ) : (
          activeTab === 2 && (
            <SelectCar
              selectedVehicle={selectedVehicle}
              setSelectedVehicle={setSelectedVehicle}
              vehicles={vehicles}
              submitError={submitError}
            />
          )
        )}
      </div>
      <div className="modal-footer">
        {activeTab && !modal?.data?.activeTab ? (
          <CancelButton onClickHandler={prevTab} i18nLabel="back" />
        ) : null}
        <div className="buttons-wrapper">
          <CancelButton onClickHandler={onClose} i18nLabel="cancel" />
          {activeTab === 0 && (
            <PrimaryButton
              onClickHandler={nextTab}
              disabled={!selectedBrand?.value}
              i18nLabel="continue"
            />
          )}
          {activeTab === 1 && (
            <PrimaryButton onClickHandler={loginIntoAccountHandler} i18nLabel="sendAndContinue" />
          )}
          {selectedBrand?.authType === 'webviewExpert' ? (
            <>
              {activeTab === 2 && (
                <PrimaryButton
                  disabled={!teslaUrlForm?.url}
                  onClickHandler={loginIntoTeslaAccountHandler}
                  i18nLabel="continue"
                />
              )}
              {activeTab === 3 && (
                <PrimaryButton
                  onClickHandler={selectCarHandler}
                  disabled={!selectedVehicle?.vin}
                  type="save"
                />
              )}
            </>
          ) : (
            activeTab === 2 && (
              <PrimaryButton
                onClickHandler={selectCarHandler}
                disabled={!selectedVehicle?.vin}
                type="save"
              />
            )
          )}
        </div>
      </div>
    </ModalWindow>
  );
};

export default AddCar;
