import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BatteryIcon } from '../../../../../assets/images/svg/battery.svg';
import { defaultValue } from '../constants';
import i18n from '../../../../../i18n';

const BatteryTooltip = (props) => {
  const { convertedCurrentPower, SOC, activeDevice, chargedForLast24Hours, dischargedForLast24Hours } = props;

  const getBatteryColor = (batteryState) => {
    switch (batteryState) {
      case -1:
        return '#F4516C';
      case 0:
        return '#FFB822';
      case 1:
        return '#60CD27';
      default:
        return '#FFB822';
    }
  };

  return (
    <>
      <BatteryIcon fill={getBatteryColor(activeDevice)} />
      <div className="sensorData-title">
        <span>{i18n.t('currentPowerForDevice')}</span>
        <span>{i18n.t('batterySoc')}</span>
        <span>{i18n.t('charged')}</span>
        <span>{i18n.t('discharged')}</span>
      </div>
      <div className="sensorData-value">
        <span>
          {convertedCurrentPower}
          {i18n.t('kWPeakOutputUnits')}
        </span>
        <span>
          {SOC || defaultValue}
          %
        </span>
        <span>
          {chargedForLast24Hours ? chargedForLast24Hours.toFixed(1) : defaultValue}
          {i18n.t('kwh')}
        </span>
        <span>
          {dischargedForLast24Hours ? `-${dischargedForLast24Hours.toFixed(1)}` : defaultValue}
          {i18n.t('kwh')}
        </span>
      </div>
    </>
  );
};

BatteryTooltip.propTypes = {
  SOC: PropTypes.number,
  chargedForLast24Hours: PropTypes.number,
  dischargedForLast24Hours: PropTypes.number,
  convertedCurrentPower: PropTypes.number.isRequired,
  activeDevice: PropTypes.number.isRequired
};

export default BatteryTooltip;
