import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsIsLoadingSelector, getModalsRemoveDataDataSelector } from '../../../redux-store/selectors/modals';
import { REMOVE_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

import './index.scss';

/**
 * Modal window to confirm deleting device
 * @memberof module:UserDevices
 */
const DeleteDeviceWindow = (props) => {
  const { onSubmit } = props;
  const dispatch = useDispatch();

  const data = useSelector(getModalsRemoveDataDataSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: REMOVE_DATA_MODAL_ID }));

  const deleteDeviceHandler = () => onSubmit({ id: data.id || data._id, url: data.url });

  return (
    <ModalWindow modalID={REMOVE_DATA_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('delDevice')}</h5>
      </div>
      {isLoading ? (
        <div className="delete-device-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <div className="text-confirmation">{i18n.t('confirmDelDevice')}</div>
            <div className="text-confirmation-detailed">{i18n.t('deleteDeviceWarning')}</div>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton onClickHandler={deleteDeviceHandler} type="delete" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

DeleteDeviceWindow.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DeleteDeviceWindow;
