import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Tooltip } from '../../../../../components';
import { getPaymentUrlRequest } from '../../../../Payments/slice';
import { getColumns } from './columns';
import { ItemsList } from '../ItemsList';
import i18n from '../../../../../i18n';
import { openModalWindow, CHOOSE_LICENSE_MODAL_ID } from '../../../../ModalWindow/slice';

/**
 * User info part.
 * @memberof module:UserDetails
 * @param {UserObject} user User Object
 */
const UserInfo = (props) => {
  const { user, myRoleType, isReadOnlyPermission } = props;
  const dispatch = useDispatch();

  const openChangeLicenseWindow = () => dispatch(
    openModalWindow({
      modalID: CHOOSE_LICENSE_MODAL_ID,
      data: {
        userId: user._id,
        licenseCode: user.license?.licenseCode
      }
    })
  );

  const redirectToPaymentHandler = () => dispatch(getPaymentUrlRequest({ userId: myRoleType === 'end_user' ? undefined : user?._id }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => getColumns({ user, myRoleType, redirectToPaymentHandler, isReadOnlyPermission, openChangeLicenseWindow }), [myRoleType, user]);
  const isEndUser = myRoleType === 'end_user';
  const settingProfileUrl = isEndUser ? '/setting-profile/' : `/setting-profile/solar-managers-users/${user._id}/`;

  return (
    <>
      <Box className="main-info tab-wrapper common-info" sx={{ p: 3, pr: 10 }}>
        {user?._id && (
          <div className="user-info-link">
            <Link
              to={settingProfileUrl}
              title={i18n.t('editTooltip')}
              className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill user-info-link"
            >
              <i className="la la-edit" />
            </Link>
          </div>
        )}
        <Box className="main-content">
          <ItemsList columns={columns} />
        </Box>
      </Box>
      <Box className="tab-wrapper main-info doc-section" sx={{ p: 3, pr: 8 }}>
        <h4 className="list-item-title main-title">
          {i18n.t('documentationTitle')}
          <Tooltip title={i18n.t('documentationTooltip')} />
        </h4>
        {user?._id ? (
          <>
            <div className="user-info-link">
              <Link
                to={`/documentation/${user._id}`}
                className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill user-info-link"
                title={i18n.t('editTooltip')}
              >
                <i className="la la-external-link" />
              </Link>
            </div>
            <ul className="list flex">
              <li className="list-item">
                <h4 className="list-item-title">
                  {i18n.t('uploadedFiles')}
                  :
                </h4>
              </li>
              <li>
                <p className="count">{user.uploadedFiles || 0}</p>
              </li>
            </ul>
          </>
        ) : null}
      </Box>
      <Box className="tab-wrapper main-info share-profile" sx={{ p: 3, pr: 8 }}>
        <h4 className="list-item-title main-title">
          {i18n.t('shareProfile')}
          <Tooltip title={i18n.t('shareProfileTooltip')} />
        </h4>
        {user?._id ? (
          <>
            <div className="user-info-link">
              <Link
                to={{ pathname: '/users/invited-users', state: { userId: user._id, myRoleType } }}
                className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill user-info-link"
                title={i18n.t('invitedUsers')}
              >
                <i className="la la-external-link" />
              </Link>
            </div>
            <ul className="list flex">
              <li className="list-item">
                <h4 className="list-item-title">
                  {i18n.t('invitedUsers')}
                  :
                </h4>
              </li>
              <li>
                <p className="count">{user.inviteesCount || 0}</p>
              </li>
            </ul>
          </>
        ) : null}
      </Box>
    </>
  );
};

UserInfo.propTypes = {
  user: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired,
  myRoleType: PropTypes.string
};

export default UserInfo;
