import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { formValueSelector } from 'redux-form';

import { DATA_LIST_ID, FORM_ID } from './constants';

const getLicenses = (state) => get(state, ['dataList', DATA_LIST_ID, 'data'], []);

export const selectValue = formValueSelector(FORM_ID);

export const selectLicenses = createSelector([getLicenses], (licenses) => licenses.map(({ title, id, description, _id }) => ({ title, value: id, description, _id })));
